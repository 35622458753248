import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import girlBg from '../../../../../assets/images/reg-guide/girl-banner.png';
import girlBgMobile from '../../../../../assets/images/reg-guide/girl-banner-mobile.png';

export const useStyles = makeStyles(({
  fontFamilies,
  palette,
  fontSizes,
  fontWeights,
  borderRadiuses,
  breakpoints,
}: Theme) => ({
  root: {
    marginBottom: 28,
    [breakpoints.up('md')]: {
      marginBottom: 36,
    },
  },
  slide: {
    display: 'flex',
    flexDirection: 'column',
    height: 481,
    overflow: 'hidden',
    background: `linear-gradient(286.92deg, ${palette.primary.dark} 1.2%, ${palette.primary.main} 53.33%, ${palette.info.dark} 114.53%)`,
    [breakpoints.up(431)]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: 'unset',
      height: 260,
    },
    [breakpoints.up('lg')]: {
      height: 435,
    },
  },
  image: {
    width: '100%',
    height: 240,
    objectFit: 'contain',
    margin: 0,
    backgroundImage: `url(${girlBgMobile})`,
    backgroundSize: 'auto 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '100%',
    backgroundPositionY: 'bottom',
    [breakpoints.up(431)]: {
      width: '47%',
      height: '100%',
      backgroundImage: `url(${girlBg})`,
      backgroundPositionX: '-1%',
    },
    [breakpoints.up(567)]: {
      flexBasis: '40%',
      backgroundSize: '94%',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 746,
    height: 'auto',
    padding: '36px 16px 50px',
    [breakpoints.up('sm')]: {
      height: '100%',
      padding: '36px 36px 36px 60px',
      justifyContent: 'flex-end',
    },
    [breakpoints.up(567)]: {
      flexBasis: '60%',
    },
  },
  title: {
    fontFamily: fontFamilies.primary,
    fontSize: 26,
    fontWeight: fontWeights.medium,
    lineHeight: 1.3,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: palette.common.white,
    marginTop: 0,
    marginBottom: 36,
    [breakpoints.up('lg')]: {
      marginBottom: 80,
      fontSize: fontSizes.large,
    },
    '& sup': {
      position: 'relative',
      lineHeight: 0,
    },
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: fontSizes.sm,
    lineHeight: 1,
    fontWeight: fontWeights.medium,
    textTransform: 'uppercase',
    marginBottom: 0,
    padding: '16px 30px',
    borderRadius: borderRadiuses.xl,
    [breakpoints.up('md')]: {
      padding: '22px 56px',
      fontSize: fontSizes.lg,
    },
    '&, &:hover, &:focus, &:active': {
      border: `2px solid ${palette.common.white}`,
      background: 'linear-gradient(245.79deg, #fde030 8.65%, #d008b2 90.3%)',
      color: palette.common.white,
      textDecoration: 'none',
    },
  },
  newLine: {
    display: 'block',
  },
  lowercase: {
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    textTransform: 'lowercase',
  },
}));
