import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  fontSizes, fontWeights, borderRadiuses, palette, breakpoints,
}: Theme) => ({
  iconWrapper: {
    '& img': {
      display: 'block',
      height: 60,
      objectFit: 'contain',
      [breakpoints.up('md')]: {
        height: 128,
      },
    },
  },
  actionButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: 30,
    padding: '16px 12px',
    border: `2px solid ${palette.common.white}`,
    borderRadius: borderRadiuses.xl,
    color: palette.common.white,
    background: `linear-gradient(245.79deg, ${palette.primary.main} 8.65%, ${palette.primary.dark} 90.3%)`,
    fontSize: fontSizes.xs,
    lineHeight: 1,
    textDecoration: 'none',
    fontWeight: fontWeights.medium,
    textTransform: 'uppercase',
    [breakpoints.up('lg')]: {
      marginTop: 40,
      padding: 22,
      fontSize: fontSizes.lg,
    },
  },
  title: {
    width: '100%',
    margin: '32px 0 12px',
    padding: '0 12px',
    color: '#a51890',
    fontSize: fontSizes.sm,
    lineHeight: 1,
    fontWeight: fontWeights.medium,
    textAlign: 'left',
    [breakpoints.up('lg')]: {
      padding: '0 30px',
      fontSize: fontSizes.xl,
    },
  },
  description: {
    flexGrow: 1,
    width: '100%',
    padding: '0 12px',
    margin: 0,
    color: palette.info.dark,
    fontSize: fontSizes.xs,
    lineHeight: 1.3,
    textAlign: 'left',
    [breakpoints.up('lg')]: {
      padding: '0 30px',
      fontSize: fontSizes.lg,
    },
  },
  link: {
    color: palette.info.dark,
  },
  index: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 28,
    height: 28,
    marginTop: 10,
    borderRadius: borderRadiuses.xxl,
    background: palette.primary.main,
    color: palette.common.white,
    fontSize: fontSizes.lg,
  },
  questionWrapper: {
    marginLeft: 4,
    lineHeight: 0,
  },
}));
