export enum InternalLinks {
  cookiePage = '/cookie-policy',
  registration = '/registration',
  login = '/login',
  resetPassword = '/reset-password',
  profilePage = '/profile',
  myLensesPage = '/my-lenses',
  myBonusesPage = '/my-bonuses',
  pointsHowToPage = '/how-to-get-redeem-points',
  programPage = '/about',
  ecpLocator = '/get-contact-lenses',
  appointment = '/appointment',
  visionProfile = '/my-acuvue-life',
  orderCreate = '/order-lenses',
  myOrdersPage = '/my-orders',
  home = '/',
  regGuide = '/registration-guide',
  deleteAccount = '/delete-account',
  ecpLocatorExternal = '/ecp-locator-external',
}
