import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import { Header } from './header';
import { Footer } from './footer';
import { useStyles } from './layout-primary.styles';
import { scrollIntoView } from '../../../../utils/scroll/scroll-into-view';

export const LayoutPrimary = (): JSX.Element => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollIntoView(ref.current);
  }, [pathname]);

  return (
    <div className={classes.root} ref={ref}>
      <div className={classes.headerAndContentWrapper}>
        <Header />
        <Outlet />
      </div>
      <div className={classes.footerWrapper}>
        <Footer />
      </div>
    </div>
  );
};
