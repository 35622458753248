import {
  Marker as GoogleMapMarker,
  MarkerClusterer,
} from '@react-google-maps/api';
import { useReactiveVar } from '@apollo/client';

import { IMarkerProps } from './marker-cluster.models';
import { LIST_ZOOM } from '../../../constants/ecp-locator';
import {
  getCampaignMarkerIcon,
  getLatLng,
  getMarkerIcon,
} from '../../../utils/ecp-locator';
import { setIsLocatorIconsCampaign } from '../../../graphql/configuration/configuration.cache';

export const MarkerCluster = ({
  ecpStores,
  onMarkerLoad,
  onMarkerClick,
  markerCode,
  ...props
}: IMarkerProps): JSX.Element => {
  const isCampaignIcon = useReactiveVar(setIsLocatorIconsCampaign);

  return (
    <MarkerClusterer
      maxZoom={LIST_ZOOM}
    >
      {(clusterer) => (
        <>
          {ecpStores.map((store) => (
            <GoogleMapMarker
              position={getLatLng(store!)}
              key={store.code}
              icon={isCampaignIcon ? getCampaignMarkerIcon(store!, markerCode!) : getMarkerIcon(store!, markerCode!)}
              onLoad={onMarkerLoad && ((marker: google.maps.Marker) => onMarkerLoad(marker, store!))}
              onClick={onMarkerClick && ((event: google.maps.MapMouseEvent) => onMarkerClick(event, store!))}
              title={store && `${store.name} ${store.street}`}
              clusterer={clusterer}
              {...props}
            />
          ))}
        </>
      )}
    </MarkerClusterer>
  );
};
