import { useIntl, FormattedMessage } from 'react-intl';

import { Helmet } from 'react-helmet';
import { SignUpSlide } from './sign-up-slide';
import { ReactNode } from 'react';
import { useStyles } from './reg-guide-page.styles';
import { RegSteps } from './reg-steps';
import { Benefits } from './benefits';

export const RegGuidePage = (): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'regGuide.title' })}
        </title>
      </Helmet>
      <SignUpSlide />
      <div className={classes.root}>
        <p
          className={classes.title}
          data-testid="save-fitting"
        >{intl.formatMessage(
          { id: 'regGuide.content.title' },
          {
            sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
            span: (chunks: ReactNode) => <span className={classes.newLine}>{chunks}</span>,
            strong: (chunks: ReactNode) => <strong className={classes.emphasis}>{chunks}</strong>,
          },
        )}</p>
        <p
          className={classes.paragraph}
          data-testid="bonus-notice"
        ><FormattedMessage id="regGuide.content.description" /></p>
        <div className={classes.stepsWrapper}>
          <RegSteps />
        </div>
      </div>
      <Benefits />
    </>
  );
};
