import { ReactComponent as VisibleSecretIcon } from '../../../assets/images/icons/visible-secret.svg';
import { ReactComponent as HiddenSecretIcon } from '../../../assets/images/icons/hidden-secret.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/images/icons/error.svg';
import { ReactComponent as WarningIcon } from '../../../assets/images/icons/warning.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/images/icons/success.svg';
import { ReactComponent as RadioUncheckedIcon } from '../../../assets/images/icons/radio-unchecked.svg';
import { ReactComponent as RadioCheckedIcon } from '../../../assets/images/icons/radio-checked.svg';
import { ReactComponent as CheckboxUncheckedIcon } from '../../../assets/images/icons/checkbox-unchecked.svg';
import { ReactComponent as CheckboxCheckedIcon } from '../../../assets/images/icons/checkbox-checked.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/images/icons/arrow-right.svg';
import { ReactComponent as ArrowRightLightIcon } from '../../../assets/images/icons/appointment/arrow-right-light.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/close.svg';
import { ReactComponent as WalletIcon } from '../../../assets/images/icons/wallet.svg';
import { ReactComponent as ExitIcon } from '../../../assets/images/icons/exit.svg';
import { ReactComponent as UserIcon } from '../../../assets/images/icons/user.svg';
import { ReactComponent as LensesIcon } from '../../../assets/images/icons/lenses.svg';
import { ReactComponent as LoginIcon } from '../../../assets/images/icons/login.svg';
import { ReactComponent as AboutProgramIcon } from '../../../assets/images/icons/about-program.svg';
import { ReactComponent as BarCodeIcon } from '../../../assets/images/icons/bar-code.svg';
import { ReactComponent as HelpIcon } from '../../../assets/images/icons/help.svg';
import { ReactComponent as MapIcon } from '../../../assets/images/icons/map.svg';
import { ReactComponent as MenuIcon } from '../../../assets/images/icons/menu.svg';
import { ReactComponent as MobileVerticalIcon } from '../../../assets/images/icons/mobile-vertical.svg';
import { ReactComponent as PercentIcon } from '../../../assets/images/icons/program/percent.svg';
import { ReactComponent as RateIcon } from '../../../assets/images/icons/rate.svg';
import { ReactComponent as RateChosenIcon } from '../../../assets/images/icons/rate-chosen.svg';
import { ReactComponent as EmailIcon } from '../../../assets/images/icons/social-media/email.svg';
import { ReactComponent as CloseMenuIcon } from '../../../assets/images/icons/close-menu.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/images/icons/arrow-down.svg';
import { ReactComponent as ExternalLinkIcon } from '../../../assets/images/icons/external-link.svg';
import { ReactComponent as HelpMarkIcon } from '../../../assets/images/icons/help-mark.svg';
import { ReactComponent as FirstAidKitIcon } from '../../../assets/images/icons/program/first-aid-kit.svg';
import { ReactComponent as InternetShopsIcon } from '../../../assets/images/icons/ecp-locator/internet-shops.svg';
import { ReactComponent as InternetShopsWrappedIcon } from '../../../assets/images/icons/ecp-locator/internet-shops-wrapped.svg';
import { ReactComponent as PhoneBlack } from '../../../assets/images/icons/ecp-locator/phone-black.svg';
import { ReactComponent as MapMarkerBlack } from '../../../assets/images/icons/vending-machine/map-marker-black.svg';
import { ReactComponent as ClockBlack } from '../../../assets/images/icons/clock-black.svg';
import { ReactComponent as InfoIcon } from '../../../assets/images/icons/info.svg';
import { ReactComponent as FilterIcon } from '../../../assets/images/icons/filter.svg';
import { ReactComponent as ListIcon } from '../../../assets/images/icons/ecp-locator/list.svg';
import { ReactComponent as MapViewIcon } from '../../../assets/images/icons/ecp-locator/map-view.svg';
import { ReactComponent as PlayIcon } from '../../../assets/images/icons/appointment/play.svg';
import { ReactComponent as WrenchBlueBg } from '../../../assets/images/icons/wrench-blue-bg.svg';
import { ReactComponent as ReminderIcon } from '../../../assets/images/icons/reminder.svg';
import { ReactComponent as DatePicker } from '../../../assets/images/icons/date-picker.svg';
import { ReactComponent as GadgetsIcon } from '../../../assets/images/icons/vision-profile/gadgets.svg';
import { ReactComponent as TimeOutsideIcon } from '../../../assets/images/icons/vision-profile/time-outside.svg';
import { ReactComponent as GiftBox } from '../../../assets/images/icons/vision-profile/gift-box.svg';
import { ReactComponent as VisionCheckTableIcon } from '../../../assets/images/icons/vision-profile/vision-check-table.svg';
import { ReactComponent as BellRoundedBlueIcon } from '../../../assets/images/icons/vision-profile/bell-rounded-blue.svg';
import { ReactComponent as LensesRoundedBlueIcon } from '../../../assets/images/icons/vision-profile/lense-rounded-blue.svg';
import { ReactComponent as GiftRoundedBlueIcon } from '../../../assets/images/icons/vision-profile/gift-rounded-blue.svg';
import { ReactComponent as GiftRoundedVioletIcon } from '../../../assets/images/icons/gift-rounded-violet.svg';
import { ReactComponent as CalendarRoundedBlueIcon } from '../../../assets/images/icons/vision-profile/calendar-rounded-blue.svg';
import { ReactComponent as PhoneRoundedBlueIcon } from '../../../assets/images/icons/vision-profile/phone-rounded-blue.svg';
import { ReactComponent as GlassesRoundedBlueIcon } from '../../../assets/images/icons/vision-profile/glasses-rounded-blue.svg';
import { ReactComponent as TreeRoundedBlueIcon } from '../../../assets/images/icons/vision-profile/tree-rounded-blue.svg';
import { ReactComponent as EyeWithEyelashesIcon } from '../../../assets/images/icons/vision-profile/eye-with-eyelashes.svg';
import { ReactComponent as PercentRoundedDottedIcon } from '../../../assets/images/icons/percent-rounded-dotted.svg';
import { ReactComponent as PercentRoundedBlueIcon } from '../../../assets/images/icons/percent-rounded-blue.svg';
import { ReactComponent as ARoundedBlueIcon } from '../../../assets/images/icons/a-rounded-blue.svg';
import { ReactComponent as GiftBonusIcon } from '../../../assets/images/icons/gift-bonus.svg';
import { ReactComponent as NoPromoIcon } from '../../../assets/images/icons/no-promo.svg';
import { ReactComponent as ConsumerBanIcon } from '../../../assets/images/icons/consumer-ban.svg';
import { ReactComponent as PurchasesConsumerBanIcon } from '../../../assets/images/icons/purchases-consumer-ban.svg';
import { ReactComponent as VoucherIcon } from '../../../assets/images/icons/voucher.svg';
import { ReactComponent as AttentionIcon } from '../../../assets/images/icons/attention.svg';
import { ReactComponent as CreditCardIcon } from '../../../assets/images/icons/vending-machine/credit-card.svg';
import { ReactComponent as ShoppingBagIcon } from '../../../assets/images/icons/shopping-bag.svg';
import { ReactComponent as OrdersCartIcon } from '../../../assets/images/icons/order/orders-cart.svg';
import { ReactComponent as TruckIcon } from '../../../assets/images/order-status/truck.svg';
import { ReactComponent as ClockIcon } from '../../../assets/images/order-status/clock.svg';
import { ReactComponent as CheckedIcon } from '../../../assets/images/order-status/checked.svg';
import { ReactComponent as CheckedBlackIcon } from '../../../assets/images/order-status/checked-black.svg';
import { ReactComponent as CancelIcon } from '../../../assets/images/order-status/cancel.svg';
import { ReactComponent as SlideLegalIcon } from '../../../assets/images/legal/legal-disclaimer.svg';
import { ReactComponent as RegBannerLegalIcon } from '../../../assets/images/legal/reg-banner-legal.svg';
import { ReactComponent as ExclamationRoundedRedIcon } from '../../../assets/images/icons/exclamation-rounded-red.svg';
import { ReactComponent as ClockRoundedGreenIcon } from '../../../assets/images/out-of-sales-true-eye/clock-rounded-green.svg';
import { ReactComponent as ScreenRoundedGreenIcon } from '../../../assets/images/out-of-sales-true-eye/screen-rounded-green.svg';
import { ReactComponent as PaintRoundedGreenIcon } from '../../../assets/images/out-of-sales-true-eye/paint-rounded-green.svg';
import { ReactComponent as Aoh1dIcon } from '../../../assets/images/out-of-sales-true-eye/aoh1d.svg';
import { ReactComponent as Aoh1dMobileIcon } from '../../../assets/images/out-of-sales-true-eye/aoh1d-mobile.svg';
import { ReactComponent as DiscountIcon } from '../../../assets/images/out-of-sales-true-eye/discount.svg';
import { ReactComponent as StoreIcon } from '../../../assets/images/out-of-sales-true-eye/store.svg';
import { ReactComponent as PhoneRetroIcon } from '../../../assets/images/out-of-sales-true-eye/phone-rounded-blue.svg';
import { ReactComponent as CallbackRoundedBlueIcon } from '../../../assets/images/out-of-sales-true-eye/callback-rounded-blue.svg';
import { ReactComponent as EmailRoundedBlueIcon } from '../../../assets/images/out-of-sales-true-eye/email-rounded-blue.svg';
import { ReactComponent as BinIcon } from '../../../assets/images/icons/order/bin.svg';
import { ReactComponent as PlusRoundedBlueIcon } from '../../../assets/images/icons/order/plus-rounded-blue.svg';
import { ReactComponent as UncheckedIcon } from '../../../assets/images/order-status/unchecked.svg';
import { ReactComponent as CartWithCrossIcon } from '../../../assets/images/icons/order/cart-with-cross.svg';
import { ReactComponent as InfoVoucherIcon } from '../../../assets/images/icons/order/info-voucher.svg';
import { ReactComponent as AccumulatePointsIcon } from '../../../assets/images/icons/order/accumulate-points.svg';
import { ReactComponent as Marker3dIcon } from '../../../assets/images/icons/order/map-marker-3d.svg';
import { ReactComponent as ClockRoundedBlueIcon } from '../../../assets/images/icons/order/clock-rounded-blue.svg';
import { ReactComponent as CheckedRoundedBlueIcon } from '../../../assets/images/icons/order/checked-rounded-blue.svg';
import { ReactComponent as MobileIcon } from '../../../assets/images/icons/order/mobile.svg';
import { ReactComponent as AddIcon } from '../../../assets/images/icons/order/add.svg';
import { ReactComponent as SolutionsCartIcon } from '../../../assets/images/icons/order/solutions-cart.svg';
import { ReactComponent as BenefitOneIcon } from '../../../assets/images/delete-account/one.svg';
import { ReactComponent as BenefitTwoIcon } from '../../../assets/images/delete-account/two.svg';
import { ReactComponent as BenefitThreeIcon } from '../../../assets/images/delete-account/three.svg';
import { ReactComponent as BenefitFourIcon } from '../../../assets/images/delete-account/four.svg';
import { ReactComponent as DeleteModalIcon } from '../../../assets/images/delete-account/modal-icon.svg';

export const icons = {
  email: EmailIcon,
  map: MapIcon,
  rate: RateIcon,
  rateChosen: RateChosenIcon,
  menu: MenuIcon,
  mobileVertical: MobileVerticalIcon,
  percent: PercentIcon,
  help: HelpIcon,
  helpMark: HelpMarkIcon,
  aboutProgram: AboutProgramIcon,
  barCode: BarCodeIcon,
  visibleSecret: VisibleSecretIcon,
  hiddenSecret: HiddenSecretIcon,
  error: ErrorIcon,
  warning: WarningIcon,
  success: SuccessIcon,
  radioUnchecked: RadioUncheckedIcon,
  radioChecked: RadioCheckedIcon,
  checkboxUnchecked: CheckboxUncheckedIcon,
  checkboxChecked: CheckboxCheckedIcon,
  arrowLeft: ArrowLeftIcon,
  arrowRight: ArrowRightIcon,
  arrowRightLight: ArrowRightLightIcon,
  close: CloseIcon,
  wallet: WalletIcon,
  exit: ExitIcon,
  user: UserIcon,
  login: LoginIcon,
  lenses: LensesIcon,
  closeMenu: CloseMenuIcon,
  arrowDown: ArrowDownIcon,
  externalLink: ExternalLinkIcon,
  firstAidKit: FirstAidKitIcon,
  internetShops: InternetShopsIcon,
  internetShopsWrapped: InternetShopsWrappedIcon,
  phoneBlack: PhoneBlack,
  mapMarkerBlack: MapMarkerBlack,
  clockBlack: ClockBlack,
  info: InfoIcon,
  filter: FilterIcon,
  list: ListIcon,
  mapView: MapViewIcon,
  play: PlayIcon,
  wrenchBlueBg: WrenchBlueBg,
  reminder: ReminderIcon,
  datePicker: DatePicker,
  timeOutside: TimeOutsideIcon,
  gadgets: GadgetsIcon,
  giftBox: GiftBox,
  visionCheckTable: VisionCheckTableIcon,
  bellRoundedBlue: BellRoundedBlueIcon,
  lensesRoundedBlue: LensesRoundedBlueIcon,
  giftRoundedBlue: GiftRoundedBlueIcon,
  giftRoundedViolet: GiftRoundedVioletIcon,
  calendarRoundedBlue: CalendarRoundedBlueIcon,
  phoneRoundedBlue: PhoneRoundedBlueIcon,
  glassesRoundedBlue: GlassesRoundedBlueIcon,
  treeRoundedBlue: TreeRoundedBlueIcon,
  eyeWithEyelashes: EyeWithEyelashesIcon,
  percentRoundedDotted: PercentRoundedDottedIcon,
  percentRoundedBlue: PercentRoundedBlueIcon,
  aRoundedBlue: ARoundedBlueIcon,
  giftBonus: GiftBonusIcon,
  noPromo: NoPromoIcon,
  consumerBan: ConsumerBanIcon,
  puschasesConsumerBan: PurchasesConsumerBanIcon,
  voucher: VoucherIcon,
  attention: AttentionIcon,
  creditCard: CreditCardIcon,
  shoppingBag: ShoppingBagIcon,
  ordersCart: OrdersCartIcon,
  truck: TruckIcon,
  clock: ClockIcon,
  cancel: CancelIcon,
  checked: CheckedIcon,
  checkedBlack: CheckedBlackIcon,
  slideLegal: SlideLegalIcon,
  regBannerLegal: RegBannerLegalIcon,
  exclamationRoundedRed: ExclamationRoundedRedIcon,
  clockRoundedGreen: ClockRoundedGreenIcon,
  screenRoundedGreen: ScreenRoundedGreenIcon,
  paintRoundedGreen: PaintRoundedGreenIcon,
  aoh1d: Aoh1dIcon,
  aoh1dMobile: Aoh1dMobileIcon,
  discount: DiscountIcon,
  store: StoreIcon,
  phoneRetro: PhoneRetroIcon,
  callbackRoundedBlue: CallbackRoundedBlueIcon,
  emailRoundedBlue: EmailRoundedBlueIcon,
  bin: BinIcon,
  plusRoundedBlue: PlusRoundedBlueIcon,
  unchecked: UncheckedIcon,
  cartWithCross: CartWithCrossIcon,
  infoVoucher: InfoVoucherIcon,
  accumulatePoints: AccumulatePointsIcon,
  marker3d: Marker3dIcon,
  clockRoundedBlue: ClockRoundedBlueIcon,
  checkedRoundedBlue: CheckedRoundedBlueIcon,
  mobile: MobileIcon,
  add: AddIcon,
  solutionsCart: SolutionsCartIcon,
  benefitOne: BenefitOneIcon,
  benefitTwo: BenefitTwoIcon,
  benefitThree: BenefitThreeIcon,
  benefitFour: BenefitFourIcon,
  deleteModal: DeleteModalIcon,
};
