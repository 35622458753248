import { useIntl } from 'react-intl';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ReactComponent as AppStore } from '../../../../../../assets/images/reg-guide/app-store.svg';
import { ReactComponent as GooglePlay } from '../../../../../../assets/images/reg-guide/google-play.svg';
import { ReactComponent as QRCode } from '../../../../../../assets/images/reg-guide/reg-guide-qr.svg';
import { useStyles } from './download-app.styles';
import { ExternalLinks } from '../../../../../../constants';
import {
  setIsLocatorIconsCampaign,
} from '../../../../../../graphql/configuration/configuration.cache';
import campaignIcon from '../../../../../../assets/images/reg-guide/campaign-icon.png';

export const DownloadApp = (): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const isCampaignIcon = useReactiveVar(setIsLocatorIconsCampaign);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div className={classes.root}>
      <div
        className={classes.apps}
        data-testid="download-app"
      >
        <div>
          <QRCode
            className={classes.qrCode}
            data-testid="qrCode"
          />
        </div>
        <div className={classes.stores}>
          <a
            href={ExternalLinks.mobileMarket}
            target="_blank"
            data-testid="appStore-link"
            aria-label={intl.formatMessage({ id: 'common.appStore' })}
          >
            <AppStore className={classes.appStore} />
          </a>
          <a
            href={ExternalLinks.mobileMarket}
            target="_blank"
            data-testid="googlePlay-link"
            aria-label={intl.formatMessage({ id: 'common.playMarket' })}
          >
            <GooglePlay className={classes.googlePlay} />
          </a>
        </div>
      </div>
      {(isCampaignIcon && !isMobile) && <img className={classes.campaignIcon} src={campaignIcon} alt="" />}
    </div>
  );
};
