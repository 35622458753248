import {
  useMemo,
  useState,
  ReactNode,
  MouseEvent as MouseEventReact,
} from 'react';
import { cx } from '@emotion/css';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FormattedMessage, useIntl } from 'react-intl';

import { useGoBack } from '../../../../../../hooks/use-go-back';
import { IconTypes } from '../../../../../common/icon';
import { useStyles } from './user-menu.styles';
import { IconButton } from '../../../../../common/icon-button';
import { DropdownMenu, IMenuItem } from '../../../../../common/dropdown-menu';
import { useLogout } from '../../../../../../graphql/user/actions/logout';
import { InternalLinks } from '../../../../../../constants';
import { IUserMenuProps } from './user-menu.models';
import { VPStatus } from '../../../../../../hooks/use-vision-profile-data';

export const UserMenu = ({ visionProfileStatus, orders, isOrders }: IUserMenuProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));
  const [dropDownAnchorEl, setDropDownAnchorEl] = useState<HTMLElement | null>(null);
  const { logout } = useLogout();
  const { hasPreviousLocationState } = useGoBack();
  const isShowOrders = process.env.REACT_APP_IS_ORDER === 'true' && !!orders?.ordersByAuthId?.length && isOrders;

  const menuItems: IMenuItem[] = useMemo(() => [{
    name: intl.formatMessage(
      { id: 'header.menuItem.findOptics' },
      { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
    ),
    icon: IconTypes.map,
    to: InternalLinks.ecpLocator,
    state: hasPreviousLocationState,
  }, {
    name: <FormattedMessage id="header.menuItem.wallet" />,
    icon: IconTypes.wallet,
    to: InternalLinks.myBonusesPage,
    state: hasPreviousLocationState,
  }, {
    name: <FormattedMessage id="header.menuItem.lenses" />,
    icon: IconTypes.lenses,
    to: InternalLinks.myLensesPage,
    state: hasPreviousLocationState,
  }, {
    name: <FormattedMessage id="header.menuItem.orders" />,
    icon: IconTypes.shoppingBag,
    to: InternalLinks.myOrdersPage,
    state: hasPreviousLocationState,
    hidden: !isShowOrders,
  }, {
    name: <FormattedMessage id="header.menuItem.myProfile" />,
    icon: IconTypes.user,
    to: InternalLinks.profilePage,
    state: hasPreviousLocationState,
  }, {
    name: intl.formatMessage(
      { id: 'header.menuItem.myAcuvueLife' },
      { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
    ),
    icon: IconTypes.eyeWithEyelashes,
    to: InternalLinks.visionProfile,
    state: hasPreviousLocationState,
    hidden: visionProfileStatus === VPStatus.INCOMPLETE,
  }, {
    name: intl.formatMessage(
      { id: 'header.menuItem.logout' },
      { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
    ),
    icon: IconTypes.exit,
    value: 'logout',
  }], [hasPreviousLocationState, orders]);

  const menuMobileItems: IMenuItem[] = useMemo(() => [{
    name: intl.formatMessage(
      { id: 'header.menuItem.findOptics' },
      { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
    ),
    icon: IconTypes.map,
    to: InternalLinks.ecpLocator,
    state: hasPreviousLocationState,
  }, {
    name: <FormattedMessage id="header.menuItem.wallet" />,
    icon: IconTypes.wallet,
    to: InternalLinks.myBonusesPage,
    state: hasPreviousLocationState,
  }, {
    name: <FormattedMessage id="header.menuItem.lenses" />,
    icon: IconTypes.lenses,
    to: InternalLinks.myLensesPage,
    state: hasPreviousLocationState,
  }, {
    name: <FormattedMessage id="header.menuItem.orders" />,
    icon: IconTypes.shoppingBag,
    to: InternalLinks.myOrdersPage,
    state: hasPreviousLocationState,
    hidden: !isShowOrders,
  }, {
    name: <FormattedMessage id="header.menuItem.myProfile" />,
    icon: IconTypes.user,
    to: InternalLinks.profilePage,
    state: hasPreviousLocationState,
  }, {
    name: <FormattedMessage id="header.about" />,
    icon: IconTypes.aboutProgram,
    to: InternalLinks.programPage,
    state: hasPreviousLocationState,
    target: '_blank',
  }, {
    name: intl.formatMessage(
      { id: 'header.menuItem.myAcuvueLife' },
      { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
    ),
    icon: IconTypes.eyeWithEyelashes,
    to: InternalLinks.visionProfile,
    state: hasPreviousLocationState,
    hidden: visionProfileStatus === VPStatus.INCOMPLETE,
  }, {
    name: intl.formatMessage(
      { id: 'header.menuItem.logout' },
      { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
    ),
    icon: IconTypes.exit,
    value: 'logout',
  }], [hasPreviousLocationState, orders]);

  const handleOpenMenuButtonClick = (e: MouseEventReact<HTMLElement>) => {
    setDropDownAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setDropDownAnchorEl(null);
  };

  const handleMenuItemClick = (value: string | null) => {
    if (value === 'logout') {
      logout();
    }

    handleMenuClose();
  };

  const userMenuIconType = (): IconTypes => {
    let type;
    if (isTablet) {
      type = IconTypes.login;
    } else if (dropDownAnchorEl) {
      type = IconTypes.closeMenu;
    } else {
      type = IconTypes.menu;
    }
    return type;
  };

  return (
    <>
      <IconButton
        iconProps={{ type: userMenuIconType() }}
        onClick={handleOpenMenuButtonClick}
        className={cx(
          { [classes.dropDownMenuOpen]: isTablet && !!dropDownAnchorEl },
          { [classes.dropDownMenu]: isTablet },
        )}
      />
      <DropdownMenu
        items={isTablet ? menuItems : menuMobileItems}
        anchorEl={dropDownAnchorEl}
        onClick={handleMenuItemClick}
        onClose={handleMenuClose}
      />
    </>
  );
};
