import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useModal } from 'mui-modal-provider';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useReactiveVar } from '@apollo/client';

import { SupportBlock } from '../../../../common/support-block';
import { HelpBlock } from '../../../../common/help-block';
import { SocialsNetworks } from '../../../../common/socials-networks';
import { useStyles } from './footer.styles';
import { ContactAJnjProModal } from '../../../../common/contact-a-jnj-pro-modal';
import { DownloadApp } from './download-app';
import { ReactComponent as Disclaimer } from '../../../../../assets/images/reg-guide/disclaimer.svg';
import { ReactComponent as DisclaimerMobile } from '../../../../../assets/images/reg-guide/disclaimer-mobile.svg';
import { ReactComponent as Logo } from '../../../../../assets/images/reg-guide/logo-reg-guide.svg';
import campaignIcon from '../../../../../assets/images/reg-guide/campaign-icon.png';
import { PolicyLinks } from '../../../../common/policy-links';
import {
  setIsLocatorIconsCampaign,
} from '../../../../../graphql/configuration/configuration.cache';

export const Footer = (): JSX.Element => {
  const classes = useStyles();
  const { showModal } = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isCampaignIcon = useReactiveVar(setIsLocatorIconsCampaign);

  const handleAskQuestionClick = () => {
    showModal(ContactAJnjProModal, {});
  };

  return (
    <footer
      className={classes.footer}
      data-testid="reg-guide-footer"
    >
      <div className={classes.inner}>
        <div className={classes.supportContainer}>
          <SupportBlock
            rootClasses={classes.supportContainerClasses}
            titleClasses={classes.supportContainerTitle}
            descriptionClasses={classes.supportContainerDescription}
            actionClasses={classes.supportActionClasses}
            title={<FormattedMessage id="footer.supportService.title" />}
            description={
              <FormattedMessage id="footer.supportService.description" />
            }
            content={<SocialsNetworks isMail />}
            phoneNumber={<FormattedMessage id="common.phoneNumber.support" />}
          />
          <HelpBlock
            rootClasses={classes.supportContainerClasses}
            titleClasses={classes.supportContainerTitle}
            descriptionClasses={classes.supportContainerDescription}
            actionClasses={classes.supportActionClasses}
            hintTextClasses={classes.supportHintText}
            title={<FormattedMessage id="regGuide.help.title" />}
            description={<FormattedMessage id="regGuide.help.description" />}
            action={{
              children: (
                <FormattedMessage id="regGuide.help.action" />
              ),
              onClick: handleAskQuestionClick,
            }}
            hintTexts={(
              <>
                <FormattedMessage id="regGuide.help.hint1" />
                <br />
                <FormattedMessage id="regGuide.help.hint2" />
              </>
            )}
          />
        </div>
        <div className={classes.policyWrapper}>
          <div className={classes.logoGroup}>
            <Logo className={classes.logo} />
            {(isCampaignIcon && isMobile) && <img className={classes.campaignIcon} src={campaignIcon} alt="" />}
            <div className={classes.policy}>
              <PolicyLinks />
            </div>
          </div>
          <DownloadApp />
        </div>
        <p className={classes.terms}><FormattedMessage id="regGuide.terms" /></p>
        {isMobile ? <DisclaimerMobile className={classes.legal} /> : <Disclaimer className={classes.legal} />}
      </div>
    </footer>
  );
};
