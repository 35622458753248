import { useModal } from 'mui-modal-provider';

import { useStyles } from './reg-steps.styles';
import { RegStep } from './reg-step';
import { FindIdModal } from '../find-id-modal';
import secondStepIcon from '../../../../../assets/images/reg-guide/prescription.svg';
import firstStepIcon from '../../../../../assets/images/reg-guide/mobile.svg';

export const RegSteps = (): JSX.Element => {
  const classes = useStyles();
  const { showModal } = useModal();

  const handleStepAction = () => {
    showModal(FindIdModal, {});
  };

  const steps = [{
    title: 'regGuide.step1.title',
    description: 'regGuide.step1.description',
    icon: <img alt="" src={firstStepIcon} />,
    actionHref: 'regGuide.step1.action.href',
    actionContent: 'regGuide.step1.action',
    index: 1,
  }, {
    title: 'regGuide.step2.title',
    description: 'regGuide.step2.description',
    icon: <img alt="" src={secondStepIcon} />,
    handleAction: handleStepAction,
    actionContent: 'regGuide.step2.action',
    index: 2,
  }];

  return (
    <ul
      className={classes.root}
      data-testid="reg-steps-list"
    >
      {steps.map(({
        title,
        description,
        actionContent,
        actionHref,
        handleAction,
        icon,
        index,
      }) => (
        <li
          className={classes.item}
          data-testid="reg-steps-item"
          key={`reg-item-${index}`}
        >
          <RegStep
            title={title}
            description={description}
            icon={icon}
            actionContent={actionContent}
            actionHref={actionHref}
            handleAction={handleAction}
          />
        </li>
      ))}
      <li aria-hidden="true" className={classes.arrow} />
    </ul>
  );
};
