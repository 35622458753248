import { useMemo } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';

import { Appointment } from '../appointment';
import { PrimaryBanner } from '../primary-banner';
import { MyBonusesLensesSection } from '../my-bonuses-lenses-section';
import { Promotions } from '../promotions';
import { MyOrders } from '../my-orders';
import { VisionProfile } from '../vision-profile';
import { EcpLocator } from '../ecp-locator';
import { Wiki } from '../wiki';
import { useStyles } from './landing-page-logged-in.styles';
import { OrderModal } from '../order-modal';
import { GET_FITTED_LENSES } from '../../../../../graphql/lenses/queries/get-fitted-lenses';
import { IFittedLenses } from '../../../../../graphql/lenses/models/get-fitted-lenses.models';
import { isUserFitted } from '../../../../../utils/lenses/is-user-fitted';
import { setC2CMode } from '../../../../../graphql/configuration/configuration.cache';
import { C2CMode } from '../../../../common/configuration-settings/configuration-settings.models';

export const LandingPageLoggedIn = (): JSX.Element => {
  const { data, loading } = useQuery<IFittedLenses>(GET_FITTED_LENSES);
  const isFittedUser = useMemo(() => {
    return isUserFitted(data);
  }, [data, isUserFitted]);
  const classes = useStyles();
  const lensesData = useMemo(() => {
    return data?.consumerLensesByAuthID;
  }, [data]);
  const c2CMode = useReactiveVar(setC2CMode);

  return (
    <>
      <Appointment />
      <PrimaryBanner />
        <div className={classes.coloredSection}>
          <MyBonusesLensesSection />
          <Promotions />
          {(process.env.REACT_APP_IS_ORDER === 'true' && !loading) && (
            <MyOrders
              isFitted={isFittedUser}
              lensesData={lensesData}
              c2CMode={c2CMode}
            />
          )}
          <VisionProfile />
        </div>
      <EcpLocator />
      <Wiki />
      {(process.env.REACT_APP_IS_ORDER === 'true' && !loading && c2CMode === C2CMode.full) && <OrderModal isFitted={isFittedUser} />}
    </>
  );
};
