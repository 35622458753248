import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentWrapper } from '../../content-wrapper';
import { useStyles } from './sign-up-slide.styles';

export const SignUpSlide = (): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ContentWrapper withoutPaddings>
        <div className={classes.slide} data-testid="slide">
          <div className={classes.content} data-testid="slide-content">
            <h1 className={classes.title} data-testid="title">
              {intl.formatMessage(
                { id: 'regGuide.banner.title' },
                {
                  sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
                  span: (chunks: ReactNode) => <span className={classes.newLine}>{chunks}</span>,
                  strong: (chunks: ReactNode) => <span className={classes.lowercase}>{chunks}</span>,
                },
              )}
            </h1>
            <a
              data-testid="signup-link"
              className={classes.button}
              href={intl.formatMessage({ id: 'regGuide.step1.action.href' })}
            >
              <FormattedMessage
                id="regGuide.banner.button"
              />
            </a>
          </div>
          <div className={classes.image} aria-hidden="true" data-testid="slide-image" />
        </div>
      </ContentWrapper>
    </div>
  );
};
